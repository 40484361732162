import api from "../api";
export const getSupervisorProjectData = (projectUUID, expiry_date, lang) => {
  return api.get(
    "guide/data-json?projectUUID=" + projectUUID + "&lang=" + lang + ""
  );
  // return api.get('guide/data-json?projectUUID='+projectUUID+'&exp='+expiry_date+'')
};
export const getSupervisorParticipantData = (
  participantUUID,
  expiry_date,
  lang
) => {
  return api.get(
    "guide/data-json?participantUUID=" + participantUUID + "&lang=" + lang + ""
  );
  // return api.get('guide/data-json?participantUUID='+participantUUID+'&exp='+expiry_date+'')
};
export const getProjectDetails = (ProjectUUID, values) => {
  return api.post("guide/fetch-details", {
    projectUUID: ProjectUUID,
    email: values.email,
    irn: values.irn,
  });
  // return api.post('guide/fetch-details?projectUUID='+ProjectUUID+'&email='+values.email+'&irn='+values.irn+'')
};

export const getParticipantDetails = (ParticipantUUID, session_id) => {
  return api.post("guide/fetch-details", {
    participantUUID: ParticipantUUID,
    sessionId: session_id,
  });
};

export const getWebRTCProps = (ParticipantUUID) => {
  return api.post("webrtc/props", {
    participantUUID: ParticipantUUID,
  });
};

export const getcontinueRecord = (
  email,
  page_name,
  button_name,
  ParticipantUUID,
  session_id
) => {
  return api.post("guide/trackParticipant", {
    email: email,
    pageName: page_name,
    buttonName: button_name,
    participantUUID: ParticipantUUID,
    sessionId: session_id,
  });
  // return api.post('guide/trackParticipant?email='+email+'&pageName='+page_name+'&buttonName='+button_name+'&participantUUID='+ParticipantUUID+'')
};
export const getbackRecord = (
  email,
  page_name,
  button_name,
  ParticipantUUID,
  session_id
) => {
  return api.post("guide/trackParticipant", {
    email: email,
    pageName: page_name,
    buttonName: button_name,
    participantUUID: ParticipantUUID,
    sessionId: session_id,
  });
};

export const getclickDialRecord = (
  email,
  page_name,
  button_name,
  ParticipantUUID,
  session_id
) => {
  return api.post("guide/trackParticipant", {
    email: email,
    pageName: page_name,
    buttonName: button_name,
    participantUUID: ParticipantUUID,
    sessionId: session_id,
  });
};

export const trackWebRTCRecords = (state, pageName, buttonName) => {
  const { email, ParticipantUUID, session_id } = state;
  const trackingOption = {
    email: email,
    pageName: pageName,
    buttonName: buttonName,
    participantUUID: ParticipantUUID,
    sessionId: session_id,
  };
  return api
    .post("guide/trackParticipant", trackingOption)
    .then((res) => {
      console.log("", res);
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const refreshAccessCode = (uuid, reason) => {
  return api.post("refresh/accesscode", {
    uuid: uuid,
    reason: reason,
  });
};

export const guideSelfhelp = (uuid, reason) => {
  return api.post("guide/self-help", {
    uuid: uuid,
    reason: reason,
  });
};

export const getProjectDetailsWithIRN = (
  ProjectUUID,
  irn,
  session_id,
  lang
) => {
  return api.post("guide/fetch-details", {
    projectUUID: ProjectUUID,
    irn: irn,
    sessionId: session_id,
    lang: lang,
  });
};

export const verifyRecaptchaToken = (token) => {
  return api.post("guide/recaptcha/verify", { token: token });
};
